import React from 'react'

import svg2 from '../../assets/svg/mave-light.svg'

import './svg-page-intro.styles.scss'


const AboutPageIntro = () => (
        <div className="svgIntro">
            <div className="svgContainer">
                <img src={svg2} alt="mave logo"/>
            </div>
            <div className="introText">
                <h3>Over Ma-Ve</h3>
                Ma-ve is gespecialiseerd in de grond gekoppelde grout injectie palen in beperkte ruimtes, zowel binnen als buiten. Onze doelstelling is om uitzonderlijke situaties op creatieve wijze op te lossen zonder in te leveren op de kwaliteit van het heiwerk. 
                <br></br><br></br>
                Doordat het grondwater in Nederland veranderd zie je een toename in verzakkingen, scheuren en dergelijke van huizen, garages. Daardoor is echt veel vraag naar heipalen.            </div>
        </div>
)

export default AboutPageIntro