import React from 'react'
import faceBook from '../../assets/svg/facebook.svg'
import axios from 'axios';
import './contact.styles.scss'


class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            naam: '',
            email: '',
            telefoon: '',
            onderwerp: '',
            bericht: '',
            mailSent: false,
            error: null
        }
    }
    render() {
        return (
            <div>
                <div className="contactLanding">
                    <div className="contactWelcome">
                        <div className="welcomeText">
                            <h3>Neem contact met ons op</h3>
                            Vul hieronder je contact gegevens in en wij nemen zo spoedig mogelijk contact op!
                            Of klik hiernaast voor onze facebook pagina.
                        </div>
                        <div className="socials">
                            <img src={faceBook} alt="facebook" />
                        </div>

                    </div>

                    <div className="formContainer">
                        <form id="contact-form">
                            <label htmlFor="naam">Naam</label>
                            <input type="text" id="naam" name="naam" required onChange={this.onChangeHandler.bind(this)} />

                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" name="email" required onChange={this.onChangeHandler.bind(this)} />

                            <label htmlFor="telefoon">Telefoonnummer</label>
                            <input type="text" id="telefoon" name="telefoon" onChange={this.onChangeHandler.bind(this)} />

                            <label htmlFor="onderwerp">Onderwerp</label>
                            <input type="text" id="onderwerp" name="onderwerp" required onChange={this.onChangeHandler.bind(this)} />

                            <label htmlFor="bericht">Bericht</label>
                            <textarea name="bericht" id="bericht" cols="10" rows="4" required onChange={this.onChangeHandler.bind(this)}></textarea>

                            <input type="submit" onClick={e => this.handleFormSubmit(e)} value="Verzenden" />
                        </form>
                        <div>
                            {this.state.mailSent &&
                                <div className="succesMail">Bedankt voor uw bericht, wij nemen zo spoedig mogelijk contact met u op.</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onChangeHandler(e) {
        this.setState({ [e.target.id]: e.target.value })
    }

    handleFormSubmit = e => {
        e.preventDefault();
        axios({
            method: 'post',
            url: "https://nieuw.ma-ve.nl/send.php",
            headers: {
                'content-type': 'application/json',
            },
            data: this.state
        })
            .then(result => {
                this.setState({
                    mailSent: true,
                })
                document.getElementById('contact-form').style.display = "none";
                document.getElementById('contact-form').style.visibility = "hidden";
            })
            .catch(error => this.setState({ error: error.response }));
    };
}

export default Contact
