import React from 'react';
import SimpleReactLightbox from "simple-react-lightbox";
import { Route, Switch } from 'react-router-dom';
import Header from './components/header/header.component'
import Layout from './pages/layout/layout'
import Footer from './components/footer/footer.component'


import HomePage from './pages/home/home'
import AboutPage from './pages/about/about';
import ContactPage from './pages/contact/contact';
import WerkWijze from './pages/werkwijze/werkwijze';
import Projecten from './pages/projecten/projecten';

import './App.css';


const App = () => {
  return (

    <div>
      <Header />
      <SimpleReactLightbox>
        <Layout>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/over-ons' component={AboutPage} />
            <Route exact path='/contact' component={ContactPage} />
            <Route exact path='/werkwijze' component={WerkWijze} />
            <Route exact path='/projecten' component={Projecten} />
          </Switch>
        </Layout>
      </SimpleReactLightbox>
      <Footer />
    </div>
  );
}

export default App;
