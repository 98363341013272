import React from 'react'
    
import { ReactComponent as Map } from '../../assets/svg/map.svg'
import { ReactComponent as Design } from '../../assets/svg/design-thick.svg'
import { ReactComponent as Truck } from '../../assets/svg/truck-thick.svg'

import './features.styles.scss'

function Features() {
    return (
        <div>
            <section className="features">
                <div className="cardTitle">
                    <h2>De voordelen van Ma-ve</h2>
                    <h3>Wat maakt ons ijzersterk?</h3>
                </div>
                <div className="cardContainer">
                    <div className="card">
                        <div className="icon">
                            <Design />
                        </div>

                        <div className="content">
                            Maatwerk voor een scherpe prijs<br></br> Voor bedrijven en particulieren
                        </div>
                    </div>

                    <div className="card">
                        <div className="icon">
                            <Map />
                        </div>

                        <div className="content">
                            Door heel Nederland
                    </div>
                    </div>

                    <div className="card">
                        <div className="icon">
                            <Truck />
                        </div>

                        <div className="content">
                            Alles in eigen beheer
                    </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Features
