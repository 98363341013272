const photos = [
  {
    src: "http://nieuw.ma-ve.nl/images/34.jpg",
    thumbnail: 'http://nieuw.ma-ve.nl/images/34.jpg',
  },
  {
    src: "http://nieuw.ma-ve.nl/images/3.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/3.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/4.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/4.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/5.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/5.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/6.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/6.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/7.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/7.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/8.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/8.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/22.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/22.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/23.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/23.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/25.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/25.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/33.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/33.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/35.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/35.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/42.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/42.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/43.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/43.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/44.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/44.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/45.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/45.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/55.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/55.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/66.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/66.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/77.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/77.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/88.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/88.jpg",
  },
  {
    src: "http://nieuw.ma-ve.nl/images/99.jpg",
    thumbnail: "http://nieuw.ma-ve.nl/images/99.jpg",
  },
];

export default photos
